import React, {Component} from 'react';
import './ScrollLinks.scss';
import Scrollchor from "react-scrollchor";

const offset = -150;
const mobileOffset = -120;
const sections = [
    {
        name: 'Courage',
        id: 'landing-section',
        offset: 0
    },
    {
        name: 'Über uns',
        id: 'about-us-section',
        offset: document.documentElement.clientWidth >= 992 ? offset : mobileOffset
    },
    {
        name: 'Marken',
        id: 'brands-section',
        offset: document.documentElement.clientWidth >= 992 ? offset : mobileOffset
    },
    {
        name: 'Kontakt & Öffnungszeiten',
        id: 'contact-section',
        offset: document.documentElement.clientWidth >= 992 ? offset : mobileOffset
    },
];

class ScrollLinks extends Component {
    render() {
        return (
            sections.map(item => {
                return (
                    <Scrollchor key={item.id}
                                className={this.props.activeSection === item.id ? "link-to-scroll active" : "link-to-scroll"}
                                to={item.id}
                                animate={{offset: item.offset}}>
                        <span/>{item.name}
                    </Scrollchor>
                )
            })
        );
    }
}

export default ScrollLinks;
