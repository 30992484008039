const workinghours = {
    fullday: [1, 2, 3, 5],
    halfday: [4, 6],
    fulldayHours: [
        {
            from: 900,
            to: 1200,
        },
        {
            from: 1430,
            to: 1800
        }
    ],
    halfdayHours: [
        {
            from: 900,
            to: 1200
        }
    ]
};

const closedString = "derzeit geschlossen";

function getOpeningHour() {
    let date = new Date();
    let string = closedString;
    if (workinghours.fullday.includes(date.getDay())) {
        return checkTimePeriod(workinghours.fulldayHours, date)
    } else if (workinghours.halfday.includes(date.getDay())) {
        return checkTimePeriod(workinghours.halfdayHours, date);
    }
    return string;
}

function checkTimePeriod(periods, date) {
    let daytime = date.getHours() * 100 + date.getMinutes();
    let open_until = 0;
    periods.forEach(period => {
        if (period.from <= daytime && daytime < period.to) {
            open_until = period.to;
        }
    });
    return toReadableTime(open_until);
}

function toReadableTime(open_until) {
    const open_until_string = open_until.toString();
    if (open_until !== 0) {
        return "noch bis " + open_until_string.substr(0, 2) + ":" + open_until_string.substr(2, 4) + " Uhr geöffnet";
    }
    return closedString;
}

export default getOpeningHour;
