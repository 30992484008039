import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import './Contact.scss';
import SectionHeadline from './utils/SectionHeadline';

import mapImage from '../assets/images/map.jpg';

const contactData = <span>
    6952 Hittisau<br/>
    Platz 338<br/>
    <a href="tel:055132626">05513 2626</a><br/>
    <a href="mailto:boutique.courage@aon.at">boutique.courage@aon.at</a>
</span>;

const textPassage = <span>
    Boutique Courage liegt im Hauptort des Vorderen Bregenzerwaldes und ist dadurch auch aus dem Rheintal
    und dem süddeutschen Raum gut erreichbar. Neben dem Auto sind auch die Angebote der öffentlichen Busse
    eine gute Alternative. Von einem der schönsten Dorfplätze des Landes ist das Geschäft in östlicher
    Richtung zu Fuß in wenigen Minuten erreichbar. Zusammen mit dem benachbarten Sportgeschäft sind
    ausreichend Parkplätze vorhanden.
</span>;


class Contact extends Component {
    render() {
        return (
            <div id="contact-section">
                <div className="wrapper" id="contact-section-opening-hours">
                    <Row>
                        <Col xl={2}> <SectionHeadline text="Kontakt & Öffnungszeiten"/></Col>
                        <Col xl={1}/>
                        <Col xl={5}>
                            <h2>Öffnungszeiten</h2>
                            <div className="opening-hours">
                                <Row>
                                    <Col xs={6}>
                                        <div className="days">Montag - Samstag</div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="times">09:00 - 12:00<br/>14:30 - 18:00</div>
                                    </Col>
                                </Row>
                                <div className="line"/>
                                <Row>
                                    <Col xs={6}>
                                        <div className="days">Donnerstag +<br/>Samstag Nachmittag</div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="times">geschlossen</div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="wrapper">
                    <Row>
                        <Col xl={3}>
                            <div className="contact">
                                <h2>Kontakt</h2>
                                <p>{contactData}</p>
                                <p>{textPassage}</p>
                                <a
                                    className="google-maps"
                                    href="https://goo.gl/maps/YZ6TRMYFCG22"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Auf Google Maps anzeigen
                                </a>
                            </div>
                        </Col>
                        <Col xl={9}/>
                    </Row>
                </div>

                <img className="map-image" src={mapImage} alt="Karte"/>
            </div>
        );
    }
}

export default Contact;
