import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "./Brands.scss";
import SectionHeadline from "./utils/SectionHeadline";

import marcOPolo from "../assets/images/brand_logos/marcopolo-logo@2x.png";
import moreAndMore from "../assets/images/brand_logos/more-and-more-logo@2x.png";
import gustav from "../assets/images/brand_logos/gustav-logo@2x.png";
import esprit from "../assets/images/brand_logos/esprit-logo@2x.png";
import blueFire from "../assets/images/brand_logos/blue-fire-logo@2x.png";
import jcSophie from "../assets/images/brand_logos/jc-sophie-logo@2x.png";
import catalanoMode from "../assets/images/brand_logos/catalano-mode-logo@2x.png";

import brand01 from "../assets/images/brand_images/brand01.jpg";
import brand02 from "../assets/images/brand_images/brand02.jpg";
import brand03 from "../assets/images/brand_images/brand03.jpg";
import brand04 from "../assets/images/brand_images/brand04.jpg";
import brand05 from "../assets/images/brand_images/brand05.jpg";

const textPassage = (
  <span>
    Gute Marken, Firmen und verlässliche Lieferanten sind das Fundament jeder
    Einzelhandelsfirma. Gerade im Modebereich ist es auch noch besonders
    wichtig, einen Mix aus <span className="bold">modernem Design</span>,
    Einzigartigkeit, <span className="bold">Liebe zum Detail</span> sowie
    Passform und einen hohen Anspruch an die Qualität im Geschäft zu vereinen.
    Dafür ist die Information auf den Modemessen und die Suche nach neuen
    innovativen Marken eine wichtige, aber auch sehr schöne Aufgabe von uns. Wir
    wollen jederzeit bereit sein, unsere Mode-Labels zu ergänzen oder
    nötigenfalls zu ersetzen.
  </span>
);

const brandImages = [
  {
    image: brand01,
    subText: "Gustav Herbst 2020",
    marginTop: 320,
    fieldsFromLeft: 0,
  },
  {
    image: brand02,
    subText: "Gustav Herbst 2020",
    marginTop: 566,
    fieldsFromLeft: 4,
  },
  {
    image: brand03,
    subText: "Gustav Herbst 2020",
    marginTop: 1045,
    fieldsFromLeft: 8,
  },
  {
    image: brand04,
    subText: "Gustav Herbst 2020",
    marginTop: 1359,
    fieldsFromLeft: 1,
  },
  {
    image: brand05,
    subText: "Gustav Herbst 2020",
    marginTop: 1694,
    fieldsFromLeft: 5,
  },
  // {
  //     image: brand06,
  //     subText: "Gustav Herbst 2019",
  //     marginTop: 2020,
  //     fieldsFromLeft: 0
  // },
];

class Brands extends Component {
  render() {
    return (
      <div id="brands-section">
        <div className="wrapper">
          <Row>
            <Col lg={2}>
              {" "}
              <SectionHeadline text={"marken"} />
            </Col>
            <Col lg={4}>
              <h3>
                Unsere Marken
                <br />
                Handverlesen
                <br />
                für Sie
              </h3>
            </Col>
            <Col lg={4}>
              <p>
                <span className="indent">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>{" "}
                {textPassage}
              </p>
            </Col>
            <Col lg={2} />
          </Row>
          <Row>
            <Col lg={6} />
            <Col lg={6}>
              <div className="brands-item-wrapper">
                <div className="brands-logos">
                  <Row>
                    <Col xs={6}>
                      <img
                        className="marc-o-polo"
                        src={marcOPolo}
                        alt="Marc o Polo"
                      />
                    </Col>
                    <Col xs={6}>
                      <img className="gustav" src={gustav} alt="Gustav" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <img
                        className="more-and-more"
                        src={moreAndMore}
                        alt="More and More"
                      />
                    </Col>
                    <Col xs={6}>
                      <img
                        className="blue-fire"
                        src={blueFire}
                        alt="Blue Fire co"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <img className="esprit" src={esprit} alt="Esprit" />
                    </Col>
                    <Col xs={6}>
                      <img
                        className="jc-sophie"
                        src={jcSophie}
                        alt="JcSophie"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <img
                        className="catalano-mode"
                        src={catalanoMode}
                        alt="Catalano Mode"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          {brandImages.map((item) => {
            return (
              <Row key={item.marginTop}>
                <Col lg={item.fieldsFromLeft} />
                <Col lg={12 - item.fieldsFromLeft}>
                  <div className="brands-item-wrapper">
                    <div className="brands-item">
                      <div style={{ marginTop: item.marginTop }}>
                        <img src={item.image} alt={item.subText} />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Brands;
