import React, {Component} from 'react';
import Header from './components/Header';
import LandingSection from './components/LandingSection';
import './App.scss';
import './Global.scss';
import AboutUs from "./components/AboutUs";
import Brands from "./components/Brands";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

class App extends Component {
    render() {
        return (
            <div className="App">
                <Header/>
                <LandingSection/>
                <AboutUs/>
                <Brands/>
                <Contact/>
                <Footer/>
            </div>
        );
    }
}

export default App;
