import React, {Component} from 'react';
import './Header.scss';

import logo from '../assets/logo.svg';
import ScrollLinks from "./utils/ScrollLinks";
import getOpeningHours from "./openingHours";

class Header extends Component {
    state = {
        activeSection: "landing-section",
        top: true,
        menuOpen: false
    };

    listenScrollEvent = () => {
        const landingSection = document.getElementById("landing-section");
        const aboutUsSection = document.getElementById("about-us-section");
        const brandsSection = document.getElementById("brands-section");
        const contactSection = document.getElementById("contact-section");

        if (this.isBetween(landingSection.offsetTop, landingSection.offsetHeight)) {
            this.setState({activeSection: "landing-section"});
        } else if (this.isBetween(aboutUsSection.offsetTop, aboutUsSection.offsetHeight)) {
            this.setState({activeSection: "about-us-section"});
        } else if (this.isBetween(brandsSection.offsetTop, brandsSection.offsetHeight)) {
            this.setState({activeSection: "brands-section"});
        } else if (this.isBetween(contactSection.offsetTop, contactSection.offsetHeight)) {
            this.setState({activeSection: "contact-section"});
        }

        if (window.scrollY < 10) {
            this.setState({top: true});
        } else {
            this.setState({top: false});
        }
    };

    isBetween = (offset, height) => {
        return (offset - 160) <= window.scrollY && window.scrollY <= (offset + height - 160);
    };

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    };

    render() {
        return (
            <header className={this.state.top ? "top" : ""}>
                <div className="wrapper">
                    <div className="header-container">
                        <div className="header-item">
                            <a href="/"><img id="logo" src={logo} alt="Logo"/></a>
                        </div>
                        <div className="header-item mobile"
                             onClick={() => this.setState({menuOpen: !this.state.menuOpen})}>
                            <div id="menu-burger" className={this.state.menuOpen ? "open" : ""}>
                                <div/>
                                <div/>
                            </div>
                            <div id="mobile-menu" className={this.state.menuOpen ? "open" : ""}>
                                <ScrollLinks activeSection={""}/>
                            </div>
                        </div>
                        <div className="header-item desktop">
                            <ScrollLinks activeSection={this.state.activeSection}/>
                            <div className="opened-batch">
                                {(getOpeningHours() !== "derzeit geschlossen") ? "jetzt geöffnet" : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
