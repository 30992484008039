import React, {Component} from 'react';
import Slider from "react-slick";
import {Col, Row} from 'react-bootstrap';
import './AboutUs.scss';
import SectionHeadline from "./utils/SectionHeadline";
import Image from "./utils/Image";

import arrow from '../assets/arrow.svg';

import margit from "../assets/images/margit.jpg";
import slider_1 from "../assets/images/slider/slider_1.jpg";
import slider_2 from "../assets/images/slider/slider_2.jpg";
import slider_3 from "../assets/images/slider/slider_3.jpg";
import slider_4 from "../assets/images/slider/slider_4.jpg";

const textPassage1 = <span>
    Seit 1993 bietet Margit Maurer in der Boutique Courage in Hittisau Damenmode in den
    <span className="bold"> Größe 32 bis 44</span> an. Ob sportlich oder elegant, hier gibt
    es das richtige Outfit für jeden Anlass. <span className="bold"> Entspannte Atmosphäre </span>
    und perfekter Service sind selbstverständlich. Änderungen werden kostenlos gemacht.
</span>;

const textPassage2 = <span>
    „Courage“ steht für „Mut zur Mode“. Gemütliche Verkaufsatmosphäre steht im Vordergrund.
    Fernab von allen Wühlkörben gibt es auf ca. 80 m² die Möglichkeit sich
    <span className="bold"> inspirieren</span> und natürlich
    <span className="bold"> fachkundig beraten</span> zu lassen. Für das Anprobieren,
    Vergleichen und Entscheiden stehen <span className="bold"> viel Zeit und Raum</span> zur
    Verfügung. Beratung und Service verhelfen auch zu „mutigen“ Modeentscheidungen. Anpassungen und
    Änderungen werden von der Chefin selbst und kostenlos durchgeführt. Um auch Müttern ein
    entspanntes Einkaufserlebnis zu ermöglichen, vergnügen sich die Kinder in einem eigenen Spielzimmer.
</span>;

const sliderImages = [
    {
        image: slider_1,
        subText: "Bildunterschrift, Foto: Johannes Fink"
    },
    {
        image: slider_4,
        subText: "Bildunterschrift, Foto: Johannes Fink"
    },
    {
        image: slider_3,
        subText: "Bildunterschrift, Foto: Johannes Fink"
    },
    {
        image: slider_2,
        subText: "Bildunterschrift, Foto: Johannes Fink"
    }
];

const textPassage3 = <span>
    Nach ihrer Ausbildung zur Einzelhandelskauffrau und zur Konfektionsschneiderin an der
    HTL-Textil in Dornbirn sammelte Margit die ersten Berufserfahrungen in verschiedenen
    Modegeschäften Vorarlbergs. In dieser Zeit reiften Wille und Entschluss zur
    Selbständigkeit.
</span>;

const textPassage4 = <span>
    Reisen in die großen Modemetropolen Europas erweiterten den Horizont und lieferten die
    ersten Kontakte zu Firmen, die in ein Modekonzept für ein kleines Geschäft im ländliche
    Raum passen. 1993 wurde der ganze Mut zusammengenommen und die Courage in der
    <span> umgebauten alten Schmiede</span> eröffnet. Neben Familie und
    Geschäft wurden auch die örtliche Kaufmannschaft „Vielfalt-Handel-Hittisau“ und die
    regionale Plattform „Ich kauf im Wald“ zu erfolgreichen Betätigungsfeldern. Bis heute
    ist sie ihrer Kernaufgabe treu geblieben:
</span>;

class AboutUs extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    state = {
        currentSlide: 1
    };

    next = () => {
        this.slider.slickNext();
    };

    previous = () => {
        this.slider.slickPrev();
    };

    render() {
        const sliderSettings = {
            centerMode: true,
            infinite: true,
            variableWidth: true,
            speed: 400,
            centerPadding: "60px",
            initialSlide: this.state.currentSlide,
            ref: c => (this.slider = c),
            afterChange: current => this.setState({currentSlide: current})
        };

        return (
            <div id="about-us-section">
                <div className="wrapper">
                    <Row>
                        <Col lg={2}>
                            <SectionHeadline text={'über uns'}/>
                        </Col>
                        <Col lg={4}><h3>Courage<br/>Mut zur Mode</h3></Col>
                        <Col lg={4}>
                            <p>
                                <span className="indent">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {textPassage1}
                            </p>
                            <p>
                                <span className="indent">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {textPassage2}
                            </p>
                        </Col>
                        <Col lg={2}/>
                    </Row>
                </div>

                <div id="slider">
                    <Slider {...sliderSettings}>
                        {
                            sliderImages.map(image => {
                                return (
                                    <div className="slider-image" key={image.image}>
                                        <img src={image.image} alt={image.subText}/>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>

                <div className="wrapper">
                    <div className="slider-navigation">
                        <img src={arrow} alt="Pfeil" onClick={this.previous}/>
                        <span>0{this.state.currentSlide + 1} / 0{sliderImages.length}</span>
                        <img src={arrow} alt="Pfeil" onClick={this.next}/>
                    </div>
                </div>

                <div className="wrapper">
                    <Row>
                        <Col lg={2}/>
                        <Col lg={4}><h3>Margit Maurer<br/>Die Chefin</h3></Col>
                        <Col lg={4}>
                            <p>
                                <span className="indent">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {textPassage3}
                            </p>
                            <p>
                                <span className="indent">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {textPassage4}
                            </p>
                        </Col>
                        <Col lg={2}/>
                    </Row>
                    <Row>
                        <Col lg={7}/>
                        <Col lg={5}>
                            <div className="quote">
                                <p>“Ich verkaufe nicht ein Produkt sondern Atmosphäre, Stimmung und Einstellung.”</p>
                                <span/>
                                <div id="author">Margit Maurer</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col lg={5}>
                        <div id="about-us-image">
                            <Image src={margit} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AboutUs;
