import React, {Component} from 'react';
import './Image.scss';

class Image extends Component {
    render() {
        return (
            <div className="image">
                <img src={this.props.src} alt="Bild"/>
                <div className="image-div">{this.props.subtext}</div>
            </div>
        );
    }
}

export default Image;
