import React, {Component} from 'react';
import './SectionHeadline.scss';

class SectionHeadline extends Component {
    render() {
        return (
            <div className="section-headline">
                <div className="line"><div/></div>
                <div className="text"><div>{this.props.text}</div></div>
            </div>
        );
    }
}

export default SectionHeadline;
