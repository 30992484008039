import React, {Component} from 'react';
import './Footer.scss';
import ScrollLinks from './utils/ScrollLinks';

import logo from '../assets/logo.svg'
import close from '../assets/close.svg';

const impressum = <div className="content">
    <h3>Impressum</h3>
    <br/><br/>
    Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
    Offenlegungspflicht laut §25 Mediengesetz.
    <br/><br/>
    Boutique Courage<br/>
    Margit Maurer<br/>
    Platz 338,<br/>
    6952 Hittisau,<br/>
    Österreich
    <br/><br/>
    Unternehmensgegenstand: Einzelunternehmen / Einzelhandel mit Bekleidung<br/>
    UID-Nummer: ATU 58784738<br/>
    Firmenbuchnummer: 42236<br/>
    Firmenbuchgericht: Landesgericht Feldkirch<br/>
    Firmensitz: 6952 Hittisau
    <br/><br/>
    Tel.: 05513 2626<br/>
    E-Mail: boutique.courage@aon.at
    <br/><br/>
    Mitglied bei: Wirtschaftskammer Vorarlberg<br/>
    Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at
    <br/><br/>
    Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft Bregenz<br/>
    Berufsbezeichnung: Einzelunternehmen / Einzelhandel mit Bekleidung<br/>
    Verleihungsstaat: Österreich
    <br/><br/>
    Quelle: Erstellt mit dem Impressum Generator von firmenwebseiten.at in Kooperation mit immotip.at
    <br/><br/>
    <h4>EU-Streitschlichtung</h4>
    Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
    Online-Streitbeilegungsplattform (OS-Plattform) informieren.
    Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission
    unter http://ec.europa.eu/odr?tid=221094395 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
    unserem Impressum.
    <br/><br/>
    Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren
    vor einer Verbraucherschlichtungsstelle teilzunehmen.
    <br/><br/>
    <h4>Haftung für Inhalte dieser Webseite</h4>
    Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen
    bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen,
    speziell für jene die seitens Dritter bereitgestellt wurden.
    <br/><br/>
    Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren, Sie
    finden die Kontaktdaten im Impressum.
    <br/><br/>
    <h4>Haftung für Links auf dieser Webseite</h4>
    Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für
    verlinkte Websites besteht laut § 17 ECG für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und
    haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn
    uns Rechtswidrigkeiten bekannt werden.
    <br/><br/>
    Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden die
    Kontaktdaten im Impressum.
    <br/><br/>
    <h4>Urheberrechtshinweis</h4>
    Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden
    wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
    <br/><br/>
    <h4>Bildernachweis</h4>
    Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.
    <br/><br/>
    Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
    <br/><br/>
    <ul>
        <li>Johannes Fink</li>
    </ul>
    <br/><br/>
    <h3>Datenschutzerklärung</h3>
    <br/><br/>
    <h4>Datenschutz</h4>
    Wir haben diese Datenschutzerklärung (Fassung 27.06.2019-221096848) verfasst, um Ihnen gemäß der Vorgaben der
    Datenschutz-Grundverordnung (EU) 2016/679 und dem Datenschutzgesetz (DSG) zu erklären, welche Informationen wir
    sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
    <br/><br/>
    Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der
    Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
    <br/><br/>
    <h4>TLS-Verschlüsselung mit https</h4>
    Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25
    Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren
    Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung
    dieser Absicherung der Datenübertragung am kleinen Schlosssymbol links oben im Browser und der Verwendung des
    Schemas https (anstatt http) als Teil unserer Internetadresse.
    <br/><br/>
    Quelle: Erstellt mit dem Datenschutz Generator von firmenwebseiten.at in Kooperation mit gewichtverlieren.at
</div>;

class Footer extends Component {
    state = {
        impressum: false
    };

    toggleImpressum = () => {
        this.setState({impressum: !this.state.impressum})
    };

    render() {
        return (
            <footer>
                <div className="wrapper">
                    <div className="footer-container">
                        <div className="footer-item"><img id="logo" src={logo} alt="Logo"/></div>
                        <div className="footer-item">
                            <ScrollLinks activeSection={""}/>
                            <span className="link-to-scroll pointer" onClick={this.toggleImpressum}>Impressum</span>
                            <span className="link-to-scroll">Copyright 2019</span>
                        </div>
                    </div>
                </div>
                <div className={this.state.impressum ? "impressum active" : "impressum"}>
                    <div className="close-icon" onClick={this.toggleImpressum}>
                        <img id="logo" src={close} alt="Close"/>
                    </div>
                    {impressum}
                </div>
            </footer>
        );
    }
}

export default Footer;
