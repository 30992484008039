import React, { Component } from "react";
import "./LandingSection.scss";
import SectionHeadline from "./utils/SectionHeadline";
import getOpeningHours from "./openingHours";
import modeBanner from "../assets/mode-banner.svg";

class LandingSection extends Component {
  render() {
    return (
      <div id="landing-section">
        <div className="wrapper">
          <img className="mode-banner" src={modeBanner} alt="Mode Banner" />
          <SectionHeadline text={"willkommen auf unserer website"} />
          <h1 className="headline">
            Die Boutique Courage ist ein Damenmodegeschäft in Hittisau.
          </h1>
          <div className="sub-content">
            <div className="opening-hours">
              Wir haben <span>{getOpeningHours()}</span>
            </div>
            <div className="location">
              Du findest uns in <span>6952 Hittisau, Platz 338</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingSection;
